import React, { FC, forwardRef, useCallback, useState, useEffect } from 'react';

import { Button } from '@onramper/oui';
import DatePicker from 'react-datepicker';

import { ReactComponent as LeftArrow } from '../../../icons/left-arrow.svg';
import { ReactComponent as Calender } from '../../../icons/calender.svg';
import { ReactComponent as Clock } from '../../../icons/clock.svg';
import AlertView from '../AlertView';
import { useNavigationContext, useTransactionContext } from '../../../providers';
import Steps from '../Steps';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './RepeatPaymentInfoView.module.css';
import { useLanguage } from '../../../hooks/useLanguage';

interface CustomDateInputProps {
  value?: string;
  onClick?: () => void;
}

const RepeatPaymentInfoView: FC = () => {
  const { formatMessage } = useLanguage();
  const { nextScreen, backScreen } = useNavigationContext();
  const {
    transaction: { fiatAmount, selectedFiat, selectedCrypto, recurringPaymentMetadata },
    setRecurringPaymentMetadata,
  } = useTransactionContext();

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [time, setTime] = useState<Date | null>(new Date());
  const [activePeriod, setActivePeriod] = useState<{ monthly: boolean; weekly: boolean }>({
    monthly: false,
    weekly: true,
  });

  useEffect(() => {
    if (startDate && time) {
      setRecurringPaymentMetadata({
        startDate: startDate.toISOString().split('T')[0],
        time: startDate.toTimeString().substring(0, 5),
      });
    }
  }, []);

  const onStartDateChange = useCallback(
    (newDate: Date | null) => {
      setStartDate(newDate);
      if (newDate) {
        setRecurringPaymentMetadata({
          ...recurringPaymentMetadata,
          startDate: newDate.toISOString().split('T')[0],
        });
      }
    },
    [recurringPaymentMetadata, setRecurringPaymentMetadata],
  );

  const onTimeChange = useCallback(
    (newTime: Date | null) => {
      setTime(newTime);
      if (newTime) {
        setRecurringPaymentMetadata({
          ...recurringPaymentMetadata,
          time: newTime.toTimeString().substring(0, 5),
        });
      }
    },
    [recurringPaymentMetadata, setRecurringPaymentMetadata],
  );

  const onChangeActivePeriod = useCallback(
    (type: 'monthly' | 'weekly') => {
      if (type === 'monthly') {
        setActivePeriod({ monthly: true, weekly: false });
      } else if (type === 'weekly') {
        setActivePeriod({ monthly: false, weekly: true });
      }
      setRecurringPaymentMetadata({
        ...recurringPaymentMetadata,
        period: type,
      });
    },
    [recurringPaymentMetadata, setRecurringPaymentMetadata],
  );

  const getTimeString = () => {
    if (time) {
      return time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }
    return '';
  };

  const getDayOfTheWeek = () => {
    if (startDate) {
      return startDate.toLocaleDateString('en-US', { weekday: 'long' });
    }
    return '';
  };

  const getStartDate = () => {
    if (startDate) {
      return startDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    }
    return '';
  };

  const getDayOfTheMonth = () => {
    if (startDate) {
      const d = startDate.toLocaleDateString('en-US', { day: 'numeric' });
      // eslint-disable-next-line max-len
      const dateOrdinal =
        d +
        ('31' == d || '21' == d || '1' == d
          ? 'st'
          : '22' == d || '2' == d
          ? 'nd'
          : '23' == d || '3' == d
          ? 'rd'
          : 'th');
      return dateOrdinal;
    }
    return '';
  };

  const renderAlertView = () => {
    // eslint-disable-next-line max-len
    const title = activePeriod.monthly
      ? formatMessage('repeatPaymentInfoView.alertView.monthly.title', {
          fiat: selectedFiat?.symbol,
          fiatAmount: fiatAmount,
          crypto: selectedCrypto?.code.toUpperCase(),
          network: selectedCrypto?.networkDisplayName,
          dayOfMonth: getDayOfTheMonth(),
          time: getTimeString(),
          startDate: getStartDate(),
        })
      : formatMessage('repeatPaymentInfoView.alertView.weekly.title', {
          fiat: selectedFiat?.symbol,
          fiatAmount: fiatAmount,
          crypto: selectedCrypto?.code.toUpperCase(),
          network: selectedCrypto?.networkDisplayName,
          dayOfWeek: getDayOfTheWeek(),
          time: getTimeString(),
          startDate: getStartDate(),
        });

    return (
      <AlertView
        title={title}
        description=""
        icon={<Calender stroke="var(--primary-text-color)" width="32" height="32" />}
        actionButton={
          <Button
            label={formatMessage('repeatPaymentInfoView.alertView.actionButton.label')}
            size="large-rectangle"
            expanded
            onClick={() => nextScreen(<Steps key="Steps" />)}
            className={styles['transaction-action-button']}
          />
        }
        secondaryActionButton={
          <Button
            label={formatMessage('repeatPaymentInfoView.alertView.editButton.label')}
            size="large-rectangle"
            expanded
            onClick={() => backScreen()}
            className={styles['transaction-action-secondary-button']}
          />
        }
      />
    );
  };

  const CustomDateInput = forwardRef<HTMLInputElement, CustomDateInputProps>(({ value, onClick }, ref) => (
    <div className={styles.customInputContainer} onClick={onClick}>
      <input className={styles.customInput} value={value} readOnly ref={ref} />
      <span className={styles.iconContainer}>
        <Calender width="24" height="24" stroke="var(--secondary-text-color)" />
      </span>
    </div>
  ));
  CustomDateInput.displayName = 'CustomDateInput';

  const CustomTimeInput = forwardRef<HTMLInputElement, CustomDateInputProps>(({ value, onClick }, ref) => (
    <div className={styles.customInputContainer} onClick={onClick}>
      <input className={styles.customInput} value={value} readOnly ref={ref} />
      <span className={styles.iconContainer}>
        <Clock stroke="var(--secondary-text-color)" />
      </span>
    </div>
  ));
  CustomTimeInput.displayName = 'CustomTimeInput';

  const onClickNext = () => {
    if (recurringPaymentMetadata?.period === undefined || recurringPaymentMetadata?.period === null) {
      setRecurringPaymentMetadata({
        ...recurringPaymentMetadata,
        period: 'weekly',
      });
    }
    nextScreen(renderAlertView());
  };

  return (
    <div className={styles['view-root']}>
      <div className={styles['view-header']}>
        <div className={styles['view-header-arrow']} onClick={() => backScreen()}>
          <LeftArrow height="24" width="24" />{' '}
        </div>
        <p className={styles['view-header-text']}>
          {formatMessage('repeatPaymentInfoView.header', {
            fiat: selectedFiat?.symbol,
            fiatAmount: fiatAmount,
            crypto: selectedCrypto?.code.toUpperCase(),
            network: selectedCrypto?.networkDisplayName,
          })}
        </p>
        <div></div>
      </div>
      <div className={styles['view-body']}>
        <p className={styles['view-body-heading']}>{formatMessage('repeatPaymentInfoView.body.heading')}</p>

        <p className={styles['view-body-form-label']}>
          {formatMessage('repeatPaymentInfoView.body.form.label.startDate')}
        </p>
        <DatePicker
          selected={startDate}
          showPopperArrow={false}
          onChange={(date) => onStartDateChange(date)}
          customInput={<CustomDateInput />}
          minDate={new Date()}
        />

        <p className={styles['view-body-form-label']}>{formatMessage('repeatPaymentInfoView.body.form.label.time')}</p>
        <DatePicker
          selected={time}
          showPopperArrow={false}
          onChange={(time) => onTimeChange(time)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          customInput={<CustomTimeInput />}
        />
        <p className={styles['view-body-form-label']}>
          {formatMessage('repeatPaymentInfoView.body.form.label.period')}
        </p>
        <div className={styles['view-body-period']}>
          <div
            onClick={() => onChangeActivePeriod('monthly')}
            className={`${styles['view-body-period-pill']} ${
              activePeriod.monthly ? styles['view-body-period-pill-active'] : ''
            } `}
          >
            {formatMessage('repeatPaymentInfoView.body.form.label.period.monthly')}
          </div>
          <div
            onClick={() => onChangeActivePeriod('weekly')}
            className={`${styles['view-body-period-pill']} ${
              activePeriod.weekly ? styles['view-body-period-pill-active'] : ''
            } `}
          >
            {formatMessage('repeatPaymentInfoView.body.form.label.period.weekly')}
          </div>
        </div>
      </div>
      <div className={styles['view-footer']}>
        <Button
          label={'Next'}
          size="large-rectangle"
          expanded
          disabled={time === null || startDate === null}
          onClick={onClickNext}
        />
      </div>
    </div>
  );
};

export default RepeatPaymentInfoView;
