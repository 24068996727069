import { useMemo } from 'react';
import { useParamContext, useTransactionContext } from '../providers';
import { useDefaults } from './useDefaults';

export const useCountry = () => {
  const { transaction } = useTransactionContext();
  const {
    params: { country },
  } = useParamContext();
  const { transactionType, selectedCountry } = transaction;
  const { countryDefault } = useDefaults(
    country ?? selectedCountry?.countryCode.toLowerCase(),
    transactionType ?? 'buy',
  );

  const countryCode = useMemo(
    () => selectedCountry?.countryCode || country || countryDefault?.country,
    [country, countryDefault?.country, selectedCountry?.countryCode],
  );

  return countryCode;
};
