import { Icon, ListItem } from '@onramper/oui';
import React, { FC, useMemo } from 'react';
import { CREDIT_CARD } from '../../../../../constants';
import { useLanguage } from '../../../../../hooks/useLanguage';
import { usePaymentMethods } from '../../../../../hooks/usePaymentTypes';
import { ReactComponent as CheckMark } from '../../../../../icons/check-mark.svg';
import { useTransactionContext } from '../../../../../providers';
import { PaymentDetail, PaymentMethod } from '../../../../../types';
import BottomOverlay from '../../../BottomOverlay/BottomOverlay';
import SelectionView from '../../../SelectionView';
import styles from './PaymentMethodPicker.module.css';
import { PaymentMethodPickerProps } from './PaymentMethodPickerProps';

const PaymentMethodPicker: FC<PaymentMethodPickerProps> = (props: PaymentMethodPickerProps) => {
  const { formatMessage } = useLanguage();
  const { title, paymentMethods, onSelectPayment } = props;
  const { transaction } = useTransactionContext();
  const { getRecommendedPaymentMethod } = usePaymentMethods();
  const { selectedPaymentMethod, selectedCrypto, selectedFiat, transactionType, fiatAmount, cryptoAmount } =
    transaction;

  const amount = transactionType === 'sell' ? cryptoAmount : fiatAmount;

  const pmsSortByLimits = useMemo(
    () =>
      [...paymentMethods].sort((a, b) => {
        const aMin = a.details?.limits?.aggregatedLimit?.min ?? 0;
        const aMax = a.details?.limits?.aggregatedLimit?.max ?? 0;
        const bMin = b.details?.limits?.aggregatedLimit?.min ?? 0;
        const bMax = b.details?.limits?.aggregatedLimit?.max ?? 0;

        const aWithinAmount = aMin <= amount && aMax >= amount;
        const bWithinAmount = bMin <= amount && bMax >= amount;

        if (aWithinAmount && !bWithinAmount) {
          return -1;
        } else if (!aWithinAmount && bWithinAmount) {
          return 1;
        }

        return 0;
      }),
    [amount, paymentMethods],
  );

  const getPaymentMethodAvatar = (description: string) => {
    const nameTags = description?.trim()?.split(' ');
    return nameTags && nameTags[0]?.charAt(0);
  };

  const renderRecommendedLabel = (paymentTypeId: string, details?: PaymentDetail) => {
    const recommendedPaymentMethod = getRecommendedPaymentMethod();
    return recommendedPaymentMethod !== CREDIT_CARD &&
      recommendedPaymentMethod === paymentTypeId &&
      details?.currencyStatus !== 'DestNotSupported' ? (
      <div className={styles['recommended-label-container']}>
        <div className={styles['recommended-label-text']}>
          {formatMessage('cryptoView.paymentMethodPicker.recommended.label')}
        </div>
      </div>
    ) : (
      ''
    );
  };

  const getSecondaryText = useMemo(
    () => (details?: PaymentDetail) => {
      const source = transactionType === 'sell' ? selectedCrypto?.code : selectedFiat?.code;
      const aggregatedLimit = details?.limits.aggregatedLimit;

      if (!details) return undefined;

      if (details.currencyStatus === 'DestNotSupported') {
        return formatMessage('cryptoView.paymentMethodPicker.unsupportedDestError', {
          source: transactionType === 'sell' ? selectedFiat?.code : `${selectedCrypto?.code}`,
          network: selectedCrypto?.networkDisplayName,
        });
      } else if (aggregatedLimit.max < amount || aggregatedLimit.min > amount) {
        return formatMessage('cryptoView.paymentMethodPicker.LimitMismatch', {
          source: source,
          min: aggregatedLimit.min,
          max: aggregatedLimit.max,
        });
      }

      return undefined;
    },
    [
      amount,
      formatMessage,
      selectedCrypto?.code,
      selectedCrypto?.networkDisplayName,
      selectedFiat?.code,
      transactionType,
    ],
  );

  return (
    <BottomOverlay title={title}>
      <SelectionView<PaymentMethod>
        keyExtractor={({ paymentTypeId }) => paymentTypeId}
        items={pmsSortByLimits}
        onSelectItem={onSelectPayment}
        renderItem={({ name, paymentTypeId, icon, details }) => (
          <ListItem
            key={paymentTypeId}
            primaryText={name || ''}
            primaryIcon={
              icon ? (
                <Icon
                  iconSrc={`https://cdn.onramper.com/icons/payments/${paymentTypeId}-colored.svg`}
                  size="medium"
                  avatarName={name}
                />
              ) : (
                <div className={styles['payment-method-avatar']}>{getPaymentMethodAvatar(name ?? '')}</div>
              )
            }
            disabled={
              details?.currencyStatus === 'DestNotSupported' ||
              details?.limits.aggregatedLimit.max < amount ||
              details?.limits.aggregatedLimit.min > amount
            }
            secondaryText={getSecondaryText(details ?? undefined)}
            tertiaryText={renderRecommendedLabel(paymentTypeId, details)}
            secondaryIcon={
              selectedPaymentMethod?.paymentTypeId === paymentTypeId ? (
                <CheckMark stroke="var(--primary-color)" />
              ) : null
            }
          />
        )}
        itemHeight={70}
      />
    </BottomOverlay>
  );
};
export default PaymentMethodPicker;
