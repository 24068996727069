import React, { FC } from "react";
import { useSelectedTab } from "../../hooks/useSelectedTab";
import { useParamContext } from "../../providers/ParamContextProvider/ParamContextProvider";
import withAuth from "../common/withAuth";
import ContainerView from "./ContainerView";
import LoadingView from "./LoadingView";
import SkipTransactionScreenView from "./SkipTransactionScreenView";
import { useIndexedDbContext } from "../../providers";

const InitScreen: FC = () => {
  const { params } = useParamContext();
  const selectedTabId = useSelectedTab();
  const { skipTransactionScreen } = params;

  if (skipTransactionScreen === undefined) {
    return <LoadingView />;
  } else if (skipTransactionScreen === true) {
    return <SkipTransactionScreenView />;
  }
  // return <ContainerView selectedTabId={selectedTabId} />;
  const AuthContainerView = withAuth(ContainerView, false);
  return <AuthContainerView />;
};

export default InitScreen;
