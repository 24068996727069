import { useParamContext } from "../providers";

export const useSelectedTab = () => {
  const { params } = useParamContext();
  const { supportSell, supportBuy } = params;

  let selectedTabId = supportSell ? 1 : 0;

  if (supportBuy && supportSell) {
    selectedTabId = 0;
  }

  return selectedTabId;
};
