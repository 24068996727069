import { useCallback } from "react";
import { useTransactionContext } from "../providers";
import { datadogRum } from "@datadog/browser-rum";
import { useCookies } from "react-cookie";

export type EventType =
  | "show-otc-transaction"
  | "click-otc-transaction"
  | "fiat-to-crypto"
  | "crypto-to-fiat";

export const useRumEvents = () => {
  const [cookies] = useCookies(["uuid"]);
  const { transaction } = useTransactionContext();
  const {
    fiatAmount,
    cryptoAmount,
    selectedFiat,
    selectedCrypto,
    selectedOnramp,
    selectedPaymentMethod,
  } = transaction;

  const triggerShowOtcTxnEvent = useCallback(() => {
    datadogRum.addAction("show-otc-transaction", {
      fiat: selectedFiat?.id,
      fiatAmount: fiatAmount,
      crypto: selectedCrypto?.id,
      onramp: selectedOnramp?.id,
      paymentMethod: selectedPaymentMethod?.paymentTypeId,
    });
  }, [
    fiatAmount,
    selectedCrypto?.id,
    selectedFiat?.id,
    selectedOnramp?.id,
    selectedPaymentMethod?.paymentTypeId,
  ]);

  const triggerClickOtcTxnEvent = useCallback(() => {
    datadogRum.addAction("click-otc-transaction", {
      fiat: selectedFiat?.id,
      fiatAmount: fiatAmount,
      crypto: selectedCrypto?.id,
      onramp: selectedOnramp?.id,
      paymentMethod: selectedPaymentMethod?.paymentTypeId,
    });
  }, [
    fiatAmount,
    selectedCrypto?.id,
    selectedFiat?.id,
    selectedOnramp?.id,
    selectedPaymentMethod?.paymentTypeId,
  ]);

  const triggerBuyCryptoEvent = useCallback(() => {
    datadogRum.addAction("fiat-to-crypto", {
      fiat: selectedFiat?.id,
      crypo: selectedCrypto?.id,
      fiatAmount: fiatAmount,
      cryptoAmount: cryptoAmount,
      onramp: selectedOnramp?.name,
      transactionId: cookies.uuid,
    });
  }, [
    cookies.uuid,
    cryptoAmount,
    fiatAmount,
    selectedCrypto?.id,
    selectedFiat?.id,
    selectedOnramp?.name,
  ]);

  const triggerSellCryptoEvent = useCallback(() => {
    datadogRum.addAction("crypto-to-fiat", {
      fiat: selectedFiat?.id,
      crypo: selectedCrypto?.id,
      fiatAmount: fiatAmount,
      cryptoAmount: cryptoAmount,
      offramp: selectedOnramp?.name,
      transactionId: cookies.uuid,
    });
  }, [
    cookies.uuid,
    cryptoAmount,
    fiatAmount,
    selectedCrypto?.id,
    selectedFiat?.id,
    selectedOnramp?.name,
  ]);

  return {
    triggerShowOtcTxnEvent,
    triggerClickOtcTxnEvent,
    triggerBuyCryptoEvent,
    triggerSellCryptoEvent,
  };
};
