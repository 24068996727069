import * as endpoints from '../config/endpoints';
import { PaymentMethodMetadata } from '../types';
import { useFetch } from '../utils/reactQuery';

type PaymentMethodMetadataResponse = {
  message: PaymentMethodMetadata;
};

export const usePaymentMetadata = () => {
  const { data, isLoading, refetch } = useFetch<PaymentMethodMetadataResponse>(
    `${endpoints.paymentTypes}`,
    {},
    {
      enabled: false,
    },
  );
  return {
    paymentMetadata: data?.message,
    isLoading,
    refetch,
  };
};
