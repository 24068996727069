import { useParamContext, useThemeContext } from "../providers";
import { changeColorMagnitude } from "../utils/commonUtils";
import Color from "color";

export const useThemes = () => {
  const { params } = useParamContext();
  const { theme } = useThemeContext();

  const {
    containerColor,
    primaryColor,
    cardColor,
    secondaryColor,
    primaryTextColor,
    secondaryTextColor,
    borderRadius,
    wgBorderRadius,
  } = params;

  const getThemeClass = () => {
    if (
      theme.name === "dark" ||
      theme.name === "light" ||
      theme.name === "bluey" ||
      theme.name === "sushi"
    ) {
      return `${theme.name}-theme`;
    }
    return "light-theme";
  };

  const getCssStyling = (className: string) => {
    const element = document.querySelector(`.${className}`);
    if (element) {
      const computedStyle = window.getComputedStyle(element);
      return {
        primaryColor: Color(
          computedStyle.getPropertyValue("--primary-color").trim()
        ).hex(),
        secondaryColor: Color(
          computedStyle.getPropertyValue("--secondary-color").trim()
        ).hex(),
        primaryTextColor: Color(
          computedStyle.getPropertyValue("--primary-text-color").trim()
        ).hex(),
        secondaryTextColor: Color(
          computedStyle.getPropertyValue("--secondary-text-color").trim()
        ).hex(),
        cardColor: Color(
          computedStyle.getPropertyValue("--card-color").trim()
        ).hex(),
        borderRadius: Number(
          computedStyle.getPropertyValue("--border-radius").trim()
        ),
      };
    }
  };

  const showDarkModeSwitch = () => {
    if (
      containerColor ||
      primaryColor ||
      cardColor ||
      secondaryColor ||
      primaryTextColor ||
      secondaryTextColor ||
      theme.name === "bluey" ||
      theme.name === "sushi"
    ) {
      return false;
    }
    return true;
  };

  const getCustomThemeStyles = () =>
    ({
      flexGrow: 1,
      display: "flex",
      "--container-color": containerColor && `#${containerColor}`,
      "--primary-color": primaryColor && `#${primaryColor}`,
      "--secondary-color": secondaryColor && `#${secondaryColor}`,
      "--primary-text-color": primaryTextColor && `#${primaryTextColor}`,
      "--secondary-text-color": secondaryTextColor && `#${secondaryTextColor}`,
      "--card-color": cardColor && `#${cardColor}`,
      "--border-radius":
        borderRadius && Number(borderRadius) <= 2 && Number(borderRadius) > 0
          ? `${borderRadius}rem`
          : "0.5rem",
      "--widget-border-radius":
        wgBorderRadius &&
        Number(wgBorderRadius) <= 2 &&
        Number(wgBorderRadius) > 0
          ? `${wgBorderRadius}rem`
          : "1rem",
      "--oui-btn-primary-bg-hover":
        primaryColor && `${changeColorMagnitude(primaryColor, -10)}`,
      "--oui-list-item-top-label-color": primaryColor && `#${primaryColor}`,
      "--oui-list-item-top-label-text-color":
        secondaryColor && `#${secondaryColor}`,
      "--oui-btn-primary-color": primaryTextColor && `#${primaryTextColor}`,
      "--oui-header-tab-option-selected-color":
        primaryColor && `#${primaryColor}`,
      "--oui-header-tab-option-selected-stroke-color":
        primaryColor && `#${primaryColor}`,
      "--oui-switch-on-bg-color": primaryColor && `#${primaryColor}`,
    }) as React.CSSProperties;

  return {
    getThemeClass,
    getCssStyling,
    showDarkModeSwitch,
    getCustomThemeStyles,
  };
};
