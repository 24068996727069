import { Icon, ListItem } from '@onramper/oui';
import React, { FC, useCallback } from 'react';
import { GB_COUNTRY_CODE } from '../../../../../constants';
import { useCountry } from '../../../../../hooks/useCountry';
import { useLanguage } from '../../../../../hooks/useLanguage';
import { OnrampCategory, useOnramps } from '../../../../../hooks/useOnramps';
import { ReactComponent as BestPerformance } from '../../../../../icons/best_performance.svg';
import { ReactComponent as Cheapest } from '../../../../../icons/cheapest.svg';
import { useTransactionContext } from '../../../../../providers';
import { Onramp, PaymentMethod, QuoteError } from '../../../../../types';
import BottomOverlay from '../../../BottomOverlay/BottomOverlay';
import SelectionView from '../../../SelectionView';
import styles from './OnrampPicker.module.css';
import { OnrampPickerProps } from './OnrampPickerProps';

type Section = {
  name: OnrampCategory;
  description: string;
  onramps: Onramp[];
};

const OnrampPicker: FC<OnrampPickerProps> = (props: OnrampPickerProps) => {
  const { formatMessage } = useLanguage();
  const { title, onSelectOnramp } = props;
  const { transaction } = useTransactionContext();
  const { selectedOnramp, selectedCrypto, selectedFiat, transactionType } = transaction;
  const { categorizedOnramps } = useOnramps();
  const countryCode = useCountry();

  const getSections = useCallback(() => {
    const sections: Section[] = [];

    if (categorizedOnramps) {
      if (categorizedOnramps.get('WithQuotes')) {
        sections.push({
          name: 'WithQuotes',
          description: '',
          onramps: [...(categorizedOnramps.get('WithQuotes') ?? [])],
        });
      }

      if (categorizedOnramps.get('Error')) {
        sections.push({
          name: 'Error',
          description: '',
          onramps: [...(categorizedOnramps.get('Error') ?? [])],
        });
      }
    }
    return sections;
  }, [categorizedOnramps]);

  const renderBestPriceBadge = () => (
    <>
      <div className={styles['tag-container']}>
        <Cheapest />
      </div>
      <span className={styles['tag-text']}>{formatMessage('cryptoView.onrampPicker.bestPrice.title')}</span>
    </>
  );

  const renderBestPerformanceBadge = () => (
    <>
      <div className={styles['tag-container']}>
        <BestPerformance />
      </div>
      <span className={styles['tag-text']}>{formatMessage('cryptoView.onrampPicker.bestPerformance.title')}</span>
    </>
  );

  const renderBadges = (item: Onramp) => {
    if (countryCode?.toUpperCase() === GB_COUNTRY_CODE) return;
    const { payout } = item;
    const badges: JSX.Element[] = [];

    if (payout > 0 && item.recommendations?.includes('BestPrice')) badges.push(renderBestPriceBadge());

    if (item.recommendations?.includes('SuccessRate')) {
      badges.push(renderBestPerformanceBadge());
    }
    if (badges.length === 0) return undefined;

    return badges.map((badge, idx) => (
      <div key={idx} className={styles['onramp-list-badge']}>
        {badge}
      </div>
    ));
  };

  const renderItemLabel = (onramp: Onramp) => {
    if (countryCode?.toUpperCase() === GB_COUNTRY_CODE) return;
    let text = '';

    // N.B.: The order of text assignment matters here.
    if ((onramp.id === 'binance' || onramp.id === 'binancep2p') && !onramp.errors) {
      text = formatMessage('cryptoView.onrampPicker.itemLabels.binanceAccount');
    }

    if (onramp.recommendations?.includes('LowKyc')) {
      text = formatMessage('cryptoView.onrampPicker.itemLabels.noDocumentUpload');
    }

    if (onramp.recommendations?.includes('SuccessfullyCompleted')) {
      text = formatMessage('cryptoView.onrampPicker.itemLabels.recentlyUsed');
    }

    return text ? <span className={styles['item-label-text']}>{text}</span> : undefined;
  };

  const sortPaymentMethods = (paymentMethods: PaymentMethod[]) => {
    const priorities = ['creditcard', 'applepay', 'googlepay'];
    return paymentMethods.sort((a, b) => {
      let priorityA = priorities.indexOf(a.paymentTypeId);
      let priorityB = priorities.indexOf(b.paymentTypeId);
      priorityA = priorityA === -1 ? Infinity : priorityA;
      priorityB = priorityB === -1 ? Infinity : priorityB;
      return priorityA - priorityB;
    });
  };

  const getErrorText = (errors: QuoteError[], { availablePaymentMethods }: Onramp) => {
    if (errors.length > 0) {
      const { type, parameter, maxAmount, minAmount } = errors[0];
      if (type === 'QuoteParameterMismatch') {
        if (parameter === 'Payment Method' && availablePaymentMethods.length > 0) {
          const sortedPaymentTypes = sortPaymentMethods(availablePaymentMethods);
          const firstThreePaymentTypes = sortedPaymentTypes.slice(0, 3).map((paymentType) => paymentType.name);
          return formatMessage('cryptoView.onrampPicker.errorText.availableWithOther', {
            payments: firstThreePaymentTypes.join(', ').replace(/, (?=[^,]*$)/, ' and '),
          });
        }
      }
      if (type === 'NoSupportedPaymentFound') {
        return formatMessage('cryptoView.onrampPicker.errorText.NoSupportedPaymentFound');
      }
      if (type === 'OnrampQuoteFetchFailed') {
        return formatMessage('cryptoView.onrampPicker.errorText.OnrampQuoteFetchFailed');
      }
      if (type === 'LimitMismatch') {
        const roundedMin = Number(minAmount).toFixed(2);
        const roundedMax = Number(maxAmount).toFixed(2);
        return formatMessage('cryptoView.onrampPicker.errorText.LimitMismatch', {
          roundedMin,
          roundedMax,
        });
      }
    }
    return formatMessage('cryptoView.onrampPicker.errorText.Unavailable');
  };

  const renderSecondaryText = (item: Onramp) => {
    const { errors } = item;

    if (errors) {
      return getErrorText(errors, item);
    }
    return renderBadges(item) as unknown as JSX.Element;
  };

  const renderPayoutDetails = (id: string, payout: number) => {
    const percentageDifference = selectedOnramp?.payout
      ? calculatePercentageDifference(payout, selectedOnramp?.payout)
      : 0;

    if (id === selectedOnramp?.id) {
      return (
        <div className={styles['payout-content']}>
          <div className={styles['you-get-text']}>You get</div>
          <div className={styles['payout-value']}>
            {typeof payout === 'number' &&
              `${payout.toFixed(5)} ${transactionType === 'buy' ? selectedCrypto?.code : selectedFiat?.code}`}
          </div>
        </div>
      );
    }

    return (
      <div className={styles['payout-content']}>
        <div className={styles['payout-value']}>
          {typeof payout === 'number' &&
            `${payout.toFixed(5)} ${transactionType === 'buy' ? selectedCrypto?.code : selectedFiat?.code}`}
        </div>
        <div
          className={`${styles['payout-percentage-difference']} ${
            percentageDifference > 0 ? styles.increase : styles.decrease
          }`}
        >
          {typeof percentageDifference === 'number' && percentageDifference > 0
            ? `+${percentageDifference.toFixed(2)}%`
            : `${percentageDifference.toFixed(2)}%`}
        </div>
      </div>
    );
  };

  const calculatePercentageDifference = (newNumber: number, originalNumber: number) =>
    ((newNumber - originalNumber) / originalNumber) * 100;

  return (
    <BottomOverlay title={title}>
      <SelectionView<Section>
        keyExtractor={({ name }) => name}
        items={getSections()}
        renderItem={(item) => {
          const { description, onramps } = item;
          return (
            <>
              <div className={styles['section-title-text']}>{description}</div>
              {onramps.map((onramp, idx) => (
                <div key={idx} className={styles['item-container']}>
                  <ListItem
                    type="rounded"
                    className={`${styles['oui-list-item-root']} ${styles['oui-list-item-root:hover']}`}
                    onClick={() => onSelectOnramp?.(onramp, idx)}
                    primaryText={onramp.name}
                    secondaryText={renderSecondaryText(onramp)}
                    primaryIcon={<Icon format="base64" iconSrc={onramp.icon} size="medium" />}
                    rightSection={!onramp.errors ? renderPayoutDetails(onramp.id, onramp.payout) : undefined}
                    disabled={onramp.errors ? true : false}
                    selected={selectedOnramp?.id === onramp.id ? true : false}
                    label={renderItemLabel(onramp)}
                  />
                </div>
              ))}
            </>
          );
        }}
        gutter={8}
        itemHeight={90}
      />
    </BottomOverlay>
  );
};
export default OnrampPicker;
