import { Icon } from '@onramper/oui';
import React, { FC, useCallback, useEffect } from 'react';
import { useOnramps } from '../../../../hooks/useOnramps';
import { useParamContext, useTransactionContext } from '../../../../providers';
import { useNavigationContext } from '../../../../providers/NavigationContextProvider';
import { Onramp } from '../../../../types';
import CurrencyConversion from '../CurrencyConversion';
import styles from './OnrampInfo.module.css';
import { OnrampInfoProps } from './OnrampInfoProps';
import OnrampPicker from './OnrampPicker/OnrampPicker';
import { ReactComponent as ChevronDown } from '../../../../icons/chevron-down.svg';
import { usePaymentMethods } from '../../../../hooks/usePaymentTypes';
import { useLanguage } from '../../../../hooks/useLanguage';

const OnrampInfo: FC<OnrampInfoProps> = (props: OnrampInfoProps) => {
  const { formatMessage } = useLanguage();
  const { loading: quoteLoading, quote } = props;
  const { onramps, recommendOnramp } = useOnramps();
  const { isError: paymentError } = usePaymentMethods();
  const { nextScreen, backScreen } = useNavigationContext();
  const { setSelectedOnramp, transaction } = useTransactionContext();
  const {
    params: { supportOtcTxn },
  } = useParamContext();
  const { selectedOnramp, selectedFiat, transactionType, isOtcTxn } = transaction;

  useEffect(() => {
    recommendOnramp();
  }, [recommendOnramp]);

  const onrampSelectHandler = useCallback(
    (item: Onramp) => {
      if (!item.errors) {
        setSelectedOnramp(item);
        backScreen();
      }
    },
    [backScreen, setSelectedOnramp],
  );

  const renderOnrampSelectionView = useCallback(
    () =>
      onramps.length > 0 &&
      nextScreen(
        <OnrampPicker
          key="OnrampPicker"
          onramps={onramps ?? []}
          title={formatMessage('cryptoView.onrampInfo.drawer.title')}
          onSelectOnramp={onrampSelectHandler}
        />,
      ),
    [onramps, nextScreen, formatMessage, onrampSelectHandler],
  );

  if (supportOtcTxn && isOtcTxn) {
    return (
      <div className={`${styles['onramp-info']} ${styles['onramp-info-info']}`}>
        <div>Larger transactions are done via OTC (over the counter service) providers.</div>
        {/* {formatMessage('cryptoView.onrampInfo.noProviderError', {
      providerType: transactionType === 'sell' ? 'offramp' : 'onramp',
    })} */}
      </div>
    );
  } else if (paymentError) {
    return (
      <div className={`${styles['onramp-info']} ${styles['onramp-info-error']}`}>
        {formatMessage('cryptoView.onrampInfo.paymentError', {
          type: transactionType === 'sell' ? 'crypto' : 'fiat',
        })}
      </div>
    );
  } else if (quote && !quoteLoading && selectedOnramp?.errors?.find((e) => e.type !== 'LimitMismatch')) {
    return (
      <div className={`${styles['onramp-info']} ${styles['onramp-info-error']}`}>
        {formatMessage('cryptoView.onrampInfo.noProviderError', {
          providerType: transactionType === 'sell' ? 'offramp' : 'onramp',
        })}
      </div>
    );
  }

  return (
    <div
      className={styles['onramp-info']}
      onClick={!quoteLoading ? () => renderOnrampSelectionView() : () => undefined}
    >
      {selectedFiat && <CurrencyConversion quote={quote ?? undefined} loading={quoteLoading} />}
      {selectedOnramp && !quoteLoading && (
        <div className={styles['onramp-selection']}>
          <span className={styles['onramp-selection-label']}> {formatMessage('cryptoView.onrampInfo.via')}</span>
          <Icon format="base64" iconSrc={selectedOnramp?.icon ?? selectedOnramp?.icon} size="xs" />
          <div className={styles['onramp-selection-text']}>
            {selectedOnramp && selectedOnramp?.name.length > 10
              ? `${selectedOnramp.name?.substring(0, 10)}...`
              : selectedOnramp?.name}
          </div>
          <ChevronDown width="10px" height="10px" stroke="var(--oui-currency-card-button-selected-color)" />
        </div>
      )}
    </div>
  );
};

export default OnrampInfo;
