import { CurrencyInfoCard, Icon } from "@onramper/oui";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useCurrencies } from "../../../../hooks/useCurrencies";
import { useDefaults } from "../../../../hooks/useDefaults";
import { useLanguage } from "../../../../hooks/useLanguage";
import { useTransactionContext } from "../../../../providers";
import { useNavigationContext } from "../../../../providers/NavigationContextProvider";
import { useParamContext } from "../../../../providers/ParamContextProvider/ParamContextProvider";
import {
  CryptoCurrency,
  FiatCurrency,
  isCryptoCurrency,
} from "../../../../types";
import { roundNumber } from "../../../../utils/commonUtils";
import { APIError, ERROR_CODES } from "../../../../utils/errors";
import ErrorView from "../../ErrorView";
import styles from "./CurrencyInfo.module.css";
import { CurrencyInfoProps } from "./CurrencyInfoProps";
import CryptoCurrencyPicker from "./CurrencyPicker/CryptoCurrencyPicker";
import FiatCurrencyPicker from "./CurrencyPicker/FiatCurrencyPicker";

const CurrencyInfo: FC<CurrencyInfoProps> = (props: CurrencyInfoProps) => {
  const { formatMessage } = useLanguage();

  const {
    setFiatAmount,
    setCryptoAmount,
    setSelectedCrypto,
    setSelectedFiat,
    transaction,
  } = useTransactionContext();
  const {
    fiatAmount,
    transactionType,
    cryptoAmount,
    selectedCountry,
    isOtcTxn,
  } = transaction;
  const { nextScreen, backScreen, currentScreenKey } = useNavigationContext();
  const { quote, error, quotesLoading } = props;
  const [fiat, setFiat] = useState<FiatCurrency | null>(null);
  const [crypto, setCrypto] = useState<CryptoCurrency | null>(null);
  const { params } = useParamContext();
  const { defaultCrypto, defaultFiat, defaultAmount, isAmountEditable } =
    transactionType ? params[transactionType] : params.buy;
  const { country, enableCountrySelector, supportOtcTxn } = params;
  const {
    getCryptoCurrencyById,
    getFiatCurrencyById,
    getFirstCrypto,
    getFirstFiat,
    isLoading: currenciesLoading,
    isError: isCurrencyError,
    currencies: { cryptoCurrencies, fiatCurrencies },
    error: currencyError,
  } = useCurrencies();
  const {
    countryDefault,
    defaults,
    isLoading: isCountryDefaultsLoading,
  } = useDefaults(country, transactionType ?? "buy");

  const [defaultSourceAmount, setDefaultSourceAmount] =
    useState<number>(fiatAmount);
  const [sourceAmount, setSourceAmount] = useState<string | undefined>(
    undefined
  ); // Used to show source currency value in the input field
  const [isAmountManuallyChanged, setIsAmountManuallyChanged] = useState(false);

  const setDefaultFiatAmount = useCallback(() => {
    const setAmounts = (amount: string | number | undefined) => {
      setFiatAmount(Number(amount));
      setSourceAmount(amount?.toString());
    };

    if (defaultAmount !== 0 && defaultFiat?.toLowerCase() === fiat?.id) {
      // give preference to default amount url param
      setAmounts(defaultAmount);
      setDefaultSourceAmount(Number(defaultAmount));
    } else if (
      !isAmountManuallyChanged &&
      countryDefault?.amount &&
      fiat?.code === countryDefault.source
    ) {
      // set country default amount if selected fiat is matching the country default fiat
      setAmounts(countryDefault.amount);
      setDefaultSourceAmount(Number(countryDefault.amount));
    } else {
      // get amount from the default amount list
      for (const cCode in defaults) {
        const { source, amount } = defaults[cCode];
        if (source.toLowerCase() === fiat?.id) {
          if (!isAmountManuallyChanged) setAmounts(amount);
          setDefaultSourceAmount(Number(amount));
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countryDefault?.amount,
    countryDefault?.source,
    defaultAmount,
    defaultFiat,
    defaults,
    fiat?.code,
    fiat?.id,
    country,
    setFiatAmount,
  ]);

  const setDefaultCryptoAmount = useCallback(() => {
    const setAmounts = (amount: string | number | undefined) => {
      setCryptoAmount(Number(amount));
      setSourceAmount(amount?.toString());
    };

    if (defaultAmount !== 0 && defaultCrypto?.toLowerCase() === crypto?.id) {
      // give preference to default amount url param
      setAmounts(defaultAmount);
      setDefaultSourceAmount(Number(defaultAmount));
    } else if (
      !isAmountManuallyChanged &&
      crypto?.code === countryDefault?.source &&
      countryDefault?.amount
    ) {
      // set country default amount if selected fiat is matching the country default fiat
      setAmounts(countryDefault.amount);
      setDefaultSourceAmount(Number(countryDefault.amount));
    } else {
      // get amount from the default amount list
      for (const cCode in defaults) {
        const { source, amount } = defaults[cCode];
        if (source === crypto?.code) {
          if (!isAmountManuallyChanged) setAmounts(amount);
          setDefaultSourceAmount(Number(amount));
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countryDefault?.amount,
    countryDefault?.source,
    crypto?.code,
    crypto?.id,
    defaultAmount,
    defaultCrypto,
    defaults,
    country,
    setCryptoAmount,
  ]);

  useEffect(() => {
    if (transactionType === "buy") {
      setDefaultFiatAmount();
    }
  }, [setDefaultFiatAmount, transactionType]);

  useEffect(() => {
    if (transactionType === "sell") {
      setDefaultCryptoAmount();
    }
  }, [setDefaultCryptoAmount, transactionType]);

  useEffect(() => {
    setIsAmountManuallyChanged(false);
  }, [selectedCountry?.countryCode, transactionType]);

  useEffect(() => {
    if (quote?.payout && quote?.payout !== 0) {
      if (transactionType === "buy") {
        setCryptoAmount(quote?.payout);
      }
      if (transactionType === "sell") {
        setFiatAmount(quote?.payout);
      }
    }
  }, [quote?.payout, setCryptoAmount, setFiatAmount, transactionType]);

  useEffect(() => {
    if (fiat) {
      setSelectedFiat(fiat);
    }
    if (crypto) {
      setSelectedCrypto(crypto);
    }
  }, [crypto, fiat, setSelectedCrypto, setSelectedFiat]);

  useEffect(() => {
    if (enableCountrySelector) {
      if (transaction.selectedFiat) {
        setFiat(transaction.selectedFiat);
      }

      if (transaction.selectedCrypto) {
        setCrypto(transaction.selectedCrypto);
      }
    }
  }, [
    enableCountrySelector,
    transaction.selectedCrypto,
    transaction.selectedFiat,
  ]);

  const renderErrorView = useCallback(
    (currencyError: Error | null) => {
      if (currentScreenKey() !== "ErrorView") {
        nextScreen(
          <ErrorView
            errorType="RegionUnsupported"
            key="ErrorView"
            title={currencyError?.message}
            description={formatMessage(
              "cryptoView.currencyInfo.errorView.countryBlockError"
            )}
            displayButton={false}
          />
        );
      }
    },
    [currentScreenKey, formatMessage, nextScreen]
  );

  useEffect(() => {
    if (isCurrencyError) {
      (currencyError as APIError).errorId === ERROR_CODES.COUNTRY_BLOCKED &&
        renderErrorView(currencyError);
    }
  }, [currencyError, isCurrencyError, renderErrorView]);

  const handleCurrencySelect = useCallback(
    (item: FiatCurrency | CryptoCurrency): void => {
      if (isCryptoCurrency(item)) {
        setCrypto(item);
      } else {
        setFiat(item);
      }
      backScreen();
    },
    [backScreen]
  );

  useEffect(() => {
    if (!isCountryDefaultsLoading) {
      if (!fiat?.id) {
        const fiatCodeToSelect =
          defaultFiat ||
          (transactionType === "sell"
            ? countryDefault?.target
            : countryDefault?.source);
        const fiatToSelect =
          fiatCodeToSelect && getFiatCurrencyById(fiatCodeToSelect);

        const currency = fiatToSelect ?? getFirstFiat();

        if (currency) {
          setFiat(currency);
        }
      }
    }
  }, [
    isCountryDefaultsLoading,
    countryDefault?.source,
    countryDefault?.target,
    defaultFiat,
    fiat?.id,
    getFiatCurrencyById,
    getFirstFiat,
    transactionType,
  ]);

  useEffect(() => {
    if (!isCountryDefaultsLoading) {
      if (!crypto?.id) {
        const cryptoCodeToSelect =
          defaultCrypto ||
          (transactionType === "sell"
            ? countryDefault?.source
            : countryDefault?.target);
        const cryptoToSelect =
          cryptoCodeToSelect && getCryptoCurrencyById(cryptoCodeToSelect);

        const currency = cryptoToSelect ?? getFirstCrypto();

        if (currency) {
          setCrypto(currency);
        }
      }
    }
  }, [
    isCountryDefaultsLoading,
    countryDefault?.source,
    countryDefault?.target,
    crypto?.id,
    defaultCrypto,
    getCryptoCurrencyById,
    getFirstCrypto,
    transactionType,
  ]);

  const renderCryptoCurrencySelectionView = useCallback(
    () =>
      cryptoCurrencies.length > 0 &&
      nextScreen(
        <CryptoCurrencyPicker
          key="CurrencyPicker"
          title={formatMessage(
            "cryptoView.currencyInfo.cryptoCurrencySelection.title"
          )}
          currencies={cryptoCurrencies}
          onSelectCurrency={handleCurrencySelect}
        />
      ),
    [cryptoCurrencies, formatMessage, handleCurrencySelect, nextScreen]
  );

  const isInvalidSourceAmount = useCallback(
    () =>
      sourceAmount === "0" || sourceAmount === "" || Number(sourceAmount) === 0,
    [sourceAmount]
  );

  const handleFiatAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAmountManuallyChanged(true);
    setSourceAmount(e.target.value);
    setFiatAmount(Number(e.target.value));
  };

  const handleCryptoAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAmountManuallyChanged(true);
    setSourceAmount(e.target.value);
    setCryptoAmount(Number(e.target.value));
  };

  const renderFiatCrrencySlectionView = useCallback(
    () =>
      fiatCurrencies.length > 0 &&
      nextScreen(
        <FiatCurrencyPicker
          key="CurrencyPicker"
          title={formatMessage(
            "cryptoView.currencyInfo.fiatCurrencySelection.title"
          )}
          currencies={fiatCurrencies}
          onSelectCurrency={handleCurrencySelect}
        />
      ),
    [fiatCurrencies, formatMessage, handleCurrencySelect, nextScreen]
  );

  const getErrorText = () => {
    switch (true) {
      case isInvalidSourceAmount():
        return formatMessage(
          "cryptoView.currencyInfo.errorText.invalidSourceAmount"
        );
      case error !== null:
        return error;
      case isCurrencyError:
        return formatMessage("cryptoView.currencyInfo.errorText.currencyError");
      default:
        return "";
    }
  };

  const getNetworkDescription = () => (
    <div className={styles["crypto-network-details"]}>
      <Icon
        className={styles.icon}
        iconSrc={`https://cdn.onramper.com/icons/crypto-networks/webp/${crypto?.network}.webp`}
        size="xxs"
        avatarName={crypto?.network}
      />
      {crypto?.networkDisplayName}
    </div>
  );

  const getRoundedAmount = useCallback(
    (amount?: number) =>
      typeof amount !== "number" ? 0 : roundNumber(amount, 8),
    []
  );

  const getPayoutAmount = useCallback(() => {
    if (
      isInvalidSourceAmount() ||
      quotesLoading ||
      error ||
      (supportOtcTxn && isOtcTxn)
    )
      return 0;
    return getRoundedAmount(quote?.payout) ?? 0;
  }, [
    supportOtcTxn,
    error,
    getRoundedAmount,
    isInvalidSourceAmount,
    isOtcTxn,
    quote?.payout,
    quotesLoading,
  ]);

  if (transactionType === "sell") {
    return (
      <>
        <CurrencyInfoCard
          name={crypto?.code}
          amount={sourceAmount ?? cryptoAmount}
          title={formatMessage("cryptoView.currencyInfo.youSell")}
          autoFocus={true}
          icon={
            crypto?.id
              ? `https://cdn.onramper.com/icons/crypto/webp/${crypto?.id}.webp`
              : ""
          }
          onClickChooseCurrency={renderCryptoCurrencySelectionView}
          onChangeAmount={handleCryptoAmountChange}
          loading={currenciesLoading && crypto?.id === undefined}
          description={crypto?.id ? getNetworkDescription() : ""}
          errorText={getErrorText()}
          error={isCurrencyError || error ? true : false}
          disabled={!isAmountEditable}
          defaultValue={defaultSourceAmount?.toString()}
        />
        <CurrencyInfoCard
          name={fiat?.code}
          title={formatMessage("cryptoView.currencyInfo.youGet")}
          amount={getPayoutAmount()}
          icon={
            fiat?.id
              ? `https://cdn.onramper.com/icons/fiats/${fiat?.id}.svg`
              : ""
          }
          onClickChooseCurrency={renderFiatCrrencySlectionView}
          className={styles["out-currency-info-card"]}
          loading={currenciesLoading}
          disabled={quotesLoading === undefined || quotesLoading === true}
          readOnly
        />
      </>
    );
  }

  return (
    <>
      <CurrencyInfoCard
        name={fiat?.code}
        title={formatMessage("cryptoView.currencyInfo.youSpend")}
        amount={sourceAmount ?? fiatAmount}
        autoFocus={true}
        icon={
          fiat?.id ? `https://cdn.onramper.com/icons/fiats/${fiat?.id}.svg` : ""
        }
        onClickChooseCurrency={renderFiatCrrencySlectionView}
        onChangeAmount={handleFiatAmountChange}
        loading={currenciesLoading && fiat?.id === undefined}
        errorText={getErrorText()}
        error={isCurrencyError || error ? true : false}
        disabled={!isAmountEditable}
        defaultValue={defaultSourceAmount?.toString()}
      />
      {
        <CurrencyInfoCard
          name={crypto?.code}
          amount={getPayoutAmount()}
          title={formatMessage("cryptoView.currencyInfo.youGet")}
          icon={
            crypto?.id
              ? `https://cdn.onramper.com/icons/crypto/webp/${crypto?.id}.webp`
              : ""
          }
          onClickChooseCurrency={renderCryptoCurrencySelectionView}
          loading={currenciesLoading}
          className={styles["out-currency-info-card"]}
          description={crypto?.id ? getNetworkDescription() : ""}
          disabled={
            (supportOtcTxn && isOtcTxn === true) ||
            quotesLoading === undefined ||
            quotesLoading === true
          }
          readOnly
        />
      }
    </>
  );
};

export default CurrencyInfo;
