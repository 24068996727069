import { TabHeader } from "@onramper/oui";
import React, { FC, useEffect, useState, Suspense, lazy } from "react";
import commonStyles from "../../../common-styles.module.css";
import { useThirdPartyCookieCheck } from "../../../hooks/useThirdPartyCookieCheck";
import { useParamContext, useTransactionContext } from "../../../providers";
import { useNavigationContext } from "../../../providers/NavigationContextProvider";
import { WidgetNotification } from "../../common/WidgetNotification/WidgetNotification";
import BuyCryptoView from "../BaseTransactionView/BuyCryptoView";
import OverlayMenu from "../MenuOverlayView/MenuOverlayView";
import { useLanguage } from "../../../hooks/useLanguage";
import { TransactionType } from "../../../types";
import LoadingView from "../LoadingView";
import SessionTimedoutView from "../SessionTimedoutView/SessionTimedoutView";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import Branding from "../../common/Branding";

const SellCryptoView = lazy(
  () => import("../BaseTransactionView/SellCryptoView")
);
const SwapCryptoView = lazy(
  () => import("../BaseTransactionView/SwapCryptoView")
);

type ContainerViewProps = {
  selectedTabId?: number;
};

const ContainerView: FC<ContainerViewProps> = ({
  selectedTabId,
}: ContainerViewProps) => {
  const { formatMessage } = useLanguage();
  const { nextScreen } = useNavigationContext();
  const { setTransactionType, resetTransaction } = useTransactionContext();
  const {
    params: { supportSell, supportBuy, supportSwap, enableAuth },
  } = useParamContext();
  // const { signature } = buy;
  const [selectedTab, setSelectedTab] = useState<number>(selectedTabId ?? 0);

  useThirdPartyCookieCheck();
  // const { validateSignature, isSignatureValError } = useValidateSignature();
  const { auth } = useAuthContext();

  const tabClickHandler = (index: number) => {
    resetTransaction();
    setSelectedTab(index);
  };

  const getTabConfig = () => {
    const tabConfig = [
      {
        flag: supportBuy,
        name: formatMessage("containerView.header.buy"),
        view: <BuyCryptoView />,
        transactionType: "buy",
      },
      {
        flag: supportSell,
        name: formatMessage("containerView.header.sell"),
        view: (
          <Suspense fallback={<LoadingView />}>
            <SellCryptoView />
          </Suspense>
        ),
        transactionType: "sell",
      },
      {
        flag: supportSwap,
        name: formatMessage("containerView.header.swap"),
        view: (
          <Suspense fallback={<LoadingView />}>
            <SwapCryptoView />
          </Suspense>
        ),
      },
    ].filter((tab) => tab.flag);

    return tabConfig;
  };

  useEffect(() => {
    if (enableAuth === true && auth.authError === "FailedToRefreshToken")
      nextScreen(<SessionTimedoutView />);
  }, [auth.authError]);

  useEffect(() => {
    setTransactionType(
      (getTabConfig()[selectedTab]?.transactionType as TransactionType) ?? "buy"
    );
  }, [selectedTab, setTransactionType]);

  const activeTabs = getTabConfig();
  const renderView = () => activeTabs[selectedTab]?.view;

  return (
    <div
      style={{
        gap: "12px",
        padding: "16px",
        boxSizing: "border-box",
      }} // TODO: move to css
      className={commonStyles.view}
    >
      <TabHeader
        tabs={activeTabs.map((tab) => tab.name)}
        onClickItem={tabClickHandler}
        tabSelected={selectedTab}
        logo={
          <Branding type="rounded" />
        }
        onMenuClick={() => nextScreen(<OverlayMenu />)}
        displayHeaderText={activeTabs.length === 1}
        headerText={`${activeTabs[0]?.name} crypto`}
      />
      <WidgetNotification />
      {renderView()}
      <div className={commonStyles["container-footer"]}>
        <Branding type="full" />
      </div>
    </div>
  );
};

export default ContainerView;
