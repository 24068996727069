import { Button, Icon, Skeleton } from '@onramper/oui';
import React, { FC, useCallback, useEffect } from 'react';
import { usePaymentMetadata } from '../../../../hooks/usePaymentMetadata';
import { usePaymentMethods } from '../../../../hooks/usePaymentTypes';
import { ReactComponent as ChevronDown } from '../../../../icons/chevron-down.svg';
import { useNavigationContext, useTransactionContext } from '../../../../providers';
import { PaymentMethod } from '../../../../types';
import styles from './PaymentMethodInfo.module.css';
import PaymentMethodPicker from './PaymentMethodPicker/PaymentMethodPicker';
import { useLanguage } from '../../../../hooks/useLanguage';

const PaymentMethodInfo: FC = () => {
  const { formatMessage } = useLanguage();
  const {
    paymentMethods,
    refetch: fetchPaymentMethods,
    selectInitialPaymentMethod,
    getRecommendedPaymentMethod,
    isLoading,
    isError,
  } = usePaymentMethods();
  const { transaction, setSelectedPaymentMethod } = useTransactionContext();
  const {
    selectedFiat,
    selectedCrypto,
    selectedPaymentMethod,
    transactionType,
    isRecurringPayment,
    fiatAmount,
    error: transactionError,
  } = transaction;
  const { nextScreen, backScreen } = useNavigationContext();
  const { paymentMetadata } = usePaymentMetadata();

  const sectionTitle =
    transactionType === 'sell'
      ? formatMessage('buyCryptoView.paymentMethod.receiveFunds')
      : formatMessage('buyCryptoView.paymentMethod.payUsing');

  useEffect(() => {
    if (selectedFiat?.id && selectedCrypto?.id && transactionType === 'buy') {
      fetchPaymentMethods();
    }
  }, [fetchPaymentMethods, selectedFiat?.id, transactionType, isRecurringPayment, selectedCrypto?.id]);

  useEffect(() => {
    if (selectedCrypto?.id && selectedFiat?.id && transactionType === 'sell') {
      fetchPaymentMethods();
    }
  }, [fetchPaymentMethods, selectedCrypto?.id, selectedFiat?.id, transactionType]);

  useEffect(() => {
    selectInitialPaymentMethod();
  }, [selectInitialPaymentMethod]);

  const paymentMethodSelectHandler = useCallback(
    (item: PaymentMethod) => {
      if (
        item.details?.currencyStatus === 'DestNotSupported' ||
        (transactionError === 'ClientLimitError' &&
          (item.details?.limits.aggregatedLimit.max < fiatAmount ||
            item.details?.limits.aggregatedLimit.min > fiatAmount))
      ) {
        return;
      }
      setSelectedPaymentMethod(item);
      backScreen();
    },
    [backScreen, fiatAmount, setSelectedPaymentMethod, transactionError],
  );

  const getPaymentMethodsWithMetadata = useCallback(() => {
    const methods: PaymentMethod[] = [];
    paymentMethods.forEach((p) => {
      const pm = paymentMetadata?.[p.paymentTypeId];
      if (pm) {
        methods.push({ ...pm, details: p.details });
      }
    });
    return methods;
  }, [paymentMetadata, paymentMethods]);

  const renderPaymentSelectionView = useCallback(
    () =>
      nextScreen(
        <PaymentMethodPicker
          key="PaymentMethodPicker"
          title={formatMessage('buyCryptoView.paymentMethod.drawer.title')}
          paymentMethods={getPaymentMethodsWithMetadata()}
          onSelectPayment={paymentMethodSelectHandler}
        />,
      ),
    [nextScreen, formatMessage, getPaymentMethodsWithMetadata, paymentMethodSelectHandler],
  );

  const renderSkeleton = () => <Skeleton height="54px" variant="rounded" />;

  if (isError) return <></>;

  return (
    <>
      <div className={styles['payment-method-view-title']}>{sectionTitle}</div>
      {(isLoading || paymentMethods.length === 0) && renderSkeleton()}
      {!isLoading && paymentMethods.length > 0 && selectedPaymentMethod?.paymentTypeId && (
        <Button
          onClick={renderPaymentSelectionView}
          className={styles['payment-method-select-button']}
          leftIcon={
            <Icon
              iconSrc={`https://cdn.onramper.com/icons/payments/${selectedPaymentMethod?.paymentTypeId}-colored.svg`}
              size="medium"
              avatarName={selectedPaymentMethod.name}
            />
          }
          rightIcon={
            <div className={styles['right-icon']}>
              <ChevronDown width="16px" height="16px" stroke="var(--oui-currency-card-button-selected-color)" />
            </div>
          }
          variant="secondary"
          label={
            <div className={styles['button-content']}>
              <div className={styles['button-text']}>{selectedPaymentMethod?.name}</div>
              {getRecommendedPaymentMethod() === selectedPaymentMethod?.paymentTypeId && (
                <div className={styles['button-secondary-text']}>
                  {formatMessage('cryptoView.paymentMethod.recommended.label')}
                </div>
              )}
            </div>
          }
        />
      )}
    </>
  );
};

export default PaymentMethodInfo;
