import { Icon, ListItem } from '@onramper/oui';
import Fuse from 'fuse.js';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CryptoCurrency } from '../../../../../types';
import BottomOverlay from '../../../BottomOverlay/BottomOverlay';
import SelectionView from '../../../SelectionView';
import styles from './CryptoCurrencyPicker.module.css';
import { CryptoPickerProps } from './CryptoPickerProps';
import { useLanguage } from '../../../../../hooks/useLanguage';

const CryptoCurrencyPicker: FC<CryptoPickerProps> = (props: CryptoPickerProps) => {
  const { formatMessage } = useLanguage();
  const { title, currencies, onSelectCurrency } = props;
  const [searchedItems, setSearchedItems] = useState<CryptoCurrency[] | undefined>();
  const topCryptos = ['dai_bsc', 'usdt_ethereum', 'usdc_ethereum', 'eth', 'btc'];
  const [filteredCryptos, setFilteredCryptos] = useState<CryptoCurrency[] | undefined>();

  const fuse = useMemo(
    () =>
      new Fuse(filteredCryptos ?? [], {
        keys: [
          {
            name: 'networkDisplayName',
            weight: 1,
          },
          {
            name: 'code',
            weight: 2,
          },
          {
            name: 'symbol',
            weight: 2,
          },
          {
            name: 'name',
            weight: 3,
          },
        ],
      }),
    [filteredCryptos],
  );

  const currencySearchHandler = useCallback(
    (searchKey: string) => {
      if (searchKey && searchKey.trim().length > 0) {
        const result = fuse.search(searchKey).map((value) => value.item);
        setSearchedItems(result);
      } else {
        setSearchedItems(undefined);
      }
    },
    [fuse],
  );

  useEffect(() => {
    const topItems = [...currencies].filter((currency) => topCryptos.includes(currency.id));
    const remainingItems = [...currencies].filter((currency) => !topCryptos.includes(currency.id));
    setFilteredCryptos([...topItems, ...remainingItems]);
  }, []);

  const renderIconWithNetwork = useCallback(
    (cryptoId: string, networkId: string, icon: string) => (
      <div className={styles.container}>
        <Icon iconSrc={icon} size="medium" avatarName={cryptoId} avatarType="square" />
        <div className={styles.icon}>
          <Icon
            className={styles.icon}
            iconSrc={`https://cdn.onramper.com/icons/crypto-networks/webp/${networkId}.webp`}
            size="xxs"
            avatarName={networkId}
          />
        </div>
      </div>
    ),
    [],
  );
  const renderTertiaryText = (networkDisplayName?: string, index?: number) => (
    <div className={styles['tertiary-text-container']}>
      {networkDisplayName}
      {(index as number) < topCryptos.length && !searchedItems && (
        <div className={styles['label-container']}>
          <div className={styles['label-text']}>
            {formatMessage('cryptoView.currencyPicker.cryptoCurrencyPicker.popular')}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <BottomOverlay title={title}>
      <SelectionView<CryptoCurrency>
        keyExtractor={({ id }) => id}
        items={searchedItems ?? filteredCryptos}
        searchable
        onSelectItem={onSelectCurrency}
        onSearch={currencySearchHandler}
        renderItem={({ code, name, id, network, networkDisplayName, icon }, index) => (
          <>
            <ListItem
              primaryText={code}
              secondaryText={name}
              primaryIcon={renderIconWithNetwork(id, network, icon ?? '')}
              tertiaryText={renderTertiaryText(networkDisplayName, index)}
            />
          </>
        )}
      />
    </BottomOverlay>
  );
};
export default CryptoCurrencyPicker;
