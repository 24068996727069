import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import * as endpoints from '../config/endpoints';
import { CREDIT_CARD } from '../constants';
import { useTransactionContext } from '../providers';
import { useParamContext } from '../providers/ParamContextProvider/ParamContextProvider';
import { Quotes, TransactionType } from '../types';
import { useFetch } from '../utils/reactQuery';
import { getNetworkWalletAddress, getWalletAddress } from '../utils/walletAddressUtils';

type FetchParams = {
  paymentMethod: string;
  amount: string;
  network: string;
  country?: string;
  uuid?: string;
  clientName?: string;
  walletAddress?: string | null;
  type: TransactionType;
  isRecurringPayment?: string;
  input?: string;
};

export const useUpdateQuote = () => {
  const { transaction } = useTransactionContext();
  const {
    transactionType,
    fiatAmount,
    cryptoAmount,
    selectedCrypto,
    selectedFiat,
    selectedPaymentMethod,
    selectedCountry,
    isRecurringPayment,
  } = transaction;
  const { params } = useParamContext();
  const { recommendation } = transactionType ? params[transactionType] : params.buy;
  const { wallets, networkWallets } = params.buy;
  const { country, buy: { txnInput } } = params;
  const [cookies] = useCookies(['uuid']);

  const { walletAddress } = useMemo(() => {
    let memoWalletAddress: string | null = null;

    if (wallets) {
      memoWalletAddress = getWalletAddress(wallets, selectedCrypto?.id.toLowerCase() ?? '');
    } else if (networkWallets) {
      memoWalletAddress = getNetworkWalletAddress(networkWallets, selectedCrypto?.network.toLowerCase() ?? '');
    }

    return {
      walletAddress: memoWalletAddress,
    };
  }, [wallets, networkWallets, selectedCrypto]);

  const getQuoteAmount = () => {
    if (transactionType === 'sell') {
      return cryptoAmount.toString();
    }
    return txnInput === 'destination' ? cryptoAmount.toString() : fiatAmount.toString();
  }

  const fetchParams: FetchParams = {
    paymentMethod: selectedPaymentMethod?.paymentTypeId ?? CREDIT_CARD,
    amount: getQuoteAmount(),
    network: selectedCrypto?.network ?? '',
    uuid: cookies.uuid,
    clientName: recommendation ?? '',
    walletAddress: walletAddress ?? '',
    isRecurringPayment: isRecurringPayment ? 'true' : 'false',
    type: transactionType ?? 'buy',
    input: txnInput,
  };

  if (country) {
    fetchParams.country = country;
  } else if (selectedCountry) {
    fetchParams.country = selectedCountry.countryCode.toLowerCase();
  }

  let url;
  if (transactionType === 'sell') {
    url = `${endpoints.quotes}/${selectedCrypto?.id}/${selectedFiat?.id}`;
  } else {
    url = `${endpoints.quotes}/${selectedFiat?.id}/${selectedCrypto?.id}`;
  }
  const {
    data: quotes,
    isLoading,
    error,
    isError,
    refetch,
  } = useFetch<Quotes>(url, fetchParams, {
    enabled: false,
  });

  return { quotes, isLoading, isError, error, refetch };
};
