import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { OnramperWidgetProps } from "../../types";
import { paramStateReducer } from "./reducers";
import {
  ParamContextActionType,
  ParamContextProps,
  ParamInitialContextProps,
} from "./types";

const initialContext: ParamInitialContextProps = {
  params: { buy: {}, sell: {} },
  setParams: () => undefined,
};

const ParamContext = createContext<ParamInitialContextProps>(initialContext);

export const useParamContext = () => {
  const context = useContext(ParamContext);
  if (context === undefined) {
    throw new Error(
      "useParamContext must be used within a ParamContextProvider"
    );
  }
  return context;
};

const ParamContextProvider: FC<ParamContextProps> = ({
  widgetParams,
  children,
}: ParamContextProps) => {
  const [params, dispatch] = useReducer(paramStateReducer, {
    buy: {},
    sell: {},
  });
  // const { country } = widgetParams;
  // const { countryDefault, refetch: fetchDefaults } = useDefaults(country);

  const setParams = useCallback(
    (data: OnramperWidgetProps) => {
      dispatch({
        type: ParamContextActionType.SET_PARAMS,
        value: data,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    // if (countryDefault) {
    // const contextWithDefaults = getParamContextWithDefaults(widgetParams, countryDefault);
    setParams(widgetParams);
    // }
  }, [setParams, widgetParams]);

  const value = useMemo(() => ({ params, setParams }), [params, setParams]);

  return (
    <ParamContext.Provider value={value}>{children}</ParamContext.Provider>
  );
};

export default ParamContextProvider;
