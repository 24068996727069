import { useEffect, useMemo, useState } from 'react';
import { useParamContext, useTransactionContext } from '../providers';
import { useLanguage } from './useLanguage';
import { useOnramps } from './useOnramps';
import { usePrevious } from './usePrevious';
import { useRumEvents } from './useRumEvents';
import { usePaymentMethods } from './usePaymentTypes';

const useLimitError = () => {
  const { triggerShowOtcTxnEvent } = useRumEvents();
  const [limitError, setLimitError] = useState('');
  const { formatMessage } = useLanguage();
  const { transaction, setTransactionError, setOtcTxn } = useTransactionContext();
  const {
    selectedOnramp,
    fiatAmount,
    cryptoAmount,
    selectedPaymentMethod,
    selectedFiat,
    selectedCrypto,
    error: transactionError,
    transactionType,
    isOtcTxn,
  } = transaction;
  const { onramps } = useOnramps();
  const { paymentMethods } = usePaymentMethods();
  const {
    params: { supportOtcTxn },
  } = useParamContext();

  const amount = transactionType === 'sell' ? cryptoAmount : fiatAmount;
  const source = transactionType === 'sell' ? selectedCrypto : selectedFiat;
  const [serverMin, setServerMin] = useState<number>(0);
  const [serverMax, setServerMax] = useState<number>(0);
  const prevFiat = usePrevious(selectedFiat?.id);
  const prevCrypto = usePrevious(selectedCrypto?.id);
  const prevPaymentMethod = usePrevious(selectedPaymentMethod?.paymentTypeId);
  const bankTransferPm = useMemo(
    () => paymentMethods.find((e) => e.paymentTypeId === 'banktransfer'),
    [paymentMethods],
  );

  useEffect(() => {
    const min = selectedPaymentMethod?.details?.limits.aggregatedLimit?.min;
    const max = selectedPaymentMethod?.details?.limits.aggregatedLimit?.max;
    const coinifyLimits = supportOtcTxn && bankTransferPm?.details?.limits.coinify;
    if (!amount) return;
    if (supportOtcTxn && transactionType === 'buy' && coinifyLimits && coinifyLimits?.max < amount) {
      setOtcTxn(true);
      triggerShowOtcTxnEvent();
    } else if (transactionError === 'ServerLimitError') {
      setOtcTxn(false);
      if (
        amount &&
        (amount < serverMin || amount > serverMax) &&
        prevFiat === selectedFiat?.id &&
        prevCrypto === selectedCrypto?.id &&
        prevPaymentMethod === selectedPaymentMethod?.paymentTypeId
      ) {
        setTransactionError('ServerLimitError');
      } else {
        setTransactionError(null);
      }
    } else {
      setOtcTxn(false);
      if (amount && (amount < min || amount > max)) {
        setTransactionError('ClientLimitError');
      } else {
        setTransactionError(null);
      }
    }
  }, [
    amount,
    selectedPaymentMethod?.paymentTypeId,
    serverMax,
    serverMin,
    setTransactionError,
    transactionError,
    selectedFiat?.id,
    selectedCrypto?.id,
    selectedPaymentMethod?.details?.limits.aggregatedLimit?.min,
    selectedPaymentMethod?.details?.limits.aggregatedLimit?.max,
    prevFiat,
    prevCrypto,
    prevPaymentMethod,
    setOtcTxn,
    supportOtcTxn,
    transactionType,
    triggerShowOtcTxnEvent,
    bankTransferPm?.details?.limits.coinify,
  ]);

  const generateLimitErrorMessage = () => {
    if (supportOtcTxn && isOtcTxn) {
      return '';
    } else if (transactionError === 'ClientLimitError') {
      return formatMessage('buyCryptoView.quoteError.limitMismatch', {
        minAmount: selectedPaymentMethod?.details?.limits.aggregatedLimit?.min,
        maxAmount: selectedPaymentMethod?.details?.limits.aggregatedLimit?.max,
        source: source?.code,
      });
    } else if (transactionError === 'ServerLimitError') {
      const limitErrors = onramps
        .filter((e) => e.errors?.find((e) => e.type === 'LimitMismatch'))
        .map((e) => {
          const err = e.errors?.find((e) => e.type === 'LimitMismatch');
          return {
            min: Number(err?.minAmount),
            max: Number(err?.maxAmount),
          };
        });

      if (limitErrors.length > 0) {
        const aggregatedLimits = limitErrors.reduce(
          (acc, current) => {
            acc.min = Math.min(acc.min, current.min);
            acc.max = Math.max(acc.max, current.max);
            return acc;
          },
          { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER },
        );
        setServerMin(aggregatedLimits.min);
        setServerMax(aggregatedLimits.max);
        return formatMessage('buyCryptoView.quoteError.serverLimitMismatch', {
          minAmount: aggregatedLimits.min,
          maxAmount: aggregatedLimits.max,
          source: source?.code,
        });
      }
    }
    return '';
  };

  useEffect(() => {
    setLimitError(generateLimitErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transactionError,
    selectedPaymentMethod?.paymentTypeId,
    selectedFiat?.id,
    selectedCrypto?.id,
    selectedOnramp?.id,
    formatMessage,
    selectedPaymentMethod?.details?.limits.aggregatedLimit?.min,
    selectedPaymentMethod?.details?.limits.aggregatedLimit?.max,
    isOtcTxn,
  ]);

  return limitError;
};

export default useLimitError;
