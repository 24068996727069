import { refresh } from "../config/endpoints";
import { APIError } from "./errors";
import indexedDbApi from "./indexedDbApi";

let refreshTokenPromise: Promise<any> | null = null;

export const refreshToken = async (headers: any) => {
  const [authToken, onramperId] =
    await indexedDbApi.getAuthTokenAndOnramperId();

  return fetch(refresh, {
    headers,
    method: "POST",
    body: JSON.stringify({
      jwt: authToken,
      onramperId: onramperId,
    }),
  })
    .then(async (response) => {
      return response.json();
    })
    .catch((error) => {
      throw new APIError(401, "Unauthorized");
    });
};

let originalRequest = async (url: string, config: any) => {
  return fetch(url, config);
};

const handleUnauthorizedRequest = async (url: string, config: any) => {
  let refreshTokenResponse;

  //This is to handle sending multiple refresh token requests at the same time
  if (!refreshTokenPromise) {
    refreshTokenPromise = refreshToken(config.headers);
    refreshTokenResponse = await refreshTokenPromise;
    refreshTokenPromise = null;

    const { jwtToken, status } = refreshTokenResponse;
    if (status === "refreshed" && jwtToken) {
      indexedDbApi.setAuthToken(jwtToken);

      const response = await originalRequest(url, {
        method: config.method,
        headers: {
          ...config.headers,
          "x-onramper-auth": jwtToken,
        },
        body: config.body,
      });
      return response.json();
    } else {
      throw new APIError(401, "Unauthorized");
    }
  } else {
    await refreshTokenPromise;
    const response = await originalRequest(url, {
      method: config.method,
      headers: {
        ...config.headers,
        "x-onramper-auth": await indexedDbApi.getAuthToken(),
      },
    });
    return response.json();
  }
};

const getUrlParameterString = (params: any, url: string) => {
  // we need to get rid of undefined, empty, null values in the params object otherwise the URLSearchParams will convert those to strings
  const filteredParams =
    (params &&
      Object.entries(params)
        .filter(
          ([, value]) => value !== undefined && value !== null && value !== ""
        )
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})) ||
    {};

  const urlWithParams =
    filteredParams && Object.keys(filteredParams).length !== 0
      ? `${url}?${new URLSearchParams(filteredParams)}`
      : url;
  return urlWithParams;
};

export const fetchApi = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: async <T>(
    url: string,
    params?: any,
    headers?: HeadersInit
  ): Promise<T> => {
    const urlWithParams = getUrlParameterString(params, url);
    const config = {
      method: "GET",
      headers,
    };

    const response = await originalRequest(urlWithParams, config);
    if (!response.ok) {
      if (response.status === 401) {
        return handleUnauthorizedRequest(urlWithParams, config);
      }
      const status = response.status || 500;
      const error = await response.json();
      throw new APIError(error.errorId ?? status, error.message);
    }
    return response.json();
  },

  post: <T>(url: string, headers?: HeadersInit, data?: unknown) => {
    const config = {
      headers,
      method: "POST",
      body: JSON.stringify(data),
    };
    return fetch(url, config).then(async (response) => {
      if (!response.ok) {
        if (response.status === 401) {
          return handleUnauthorizedRequest(url, config);
        }
        const status = response.status || 500;
        const error = await response.json();
        throw new APIError(error.errorId ?? status, error.message);
      }
      return response.json();
    });
  },

  patch: <T>(url: string, headers?: HeadersInit, data?: unknown) => {
    const config = {
      headers,
      method: "PATCH",
      body: JSON.stringify(data),
    };
    return fetch(url, config).then(async (response) => {
      if (!response.ok) {
        if (response.status === 401) {
          return handleUnauthorizedRequest(url, config);
        }
        const status = response.status || 500;
        const error = await response.json();
        throw new APIError(error.errorId ?? status, error.message);
      }
      return response.json();
    });
  },
};
