import { useCallback, useEffect, useState } from "react";
import { onrampMetadata } from "../metadata/onrampMetadata";
import { useParamContext, useTransactionContext } from "../providers";
import { Onramp } from "../types";
import { useUpdateQuote } from "./useUpdateQuote";

export type OnrampCategory = "WithQuotes" | "Error";

export const useOnramps = () => {
  const {
    setSelectedOnramp,
    setTransactionError,
    transaction: { transactionType },
  } = useTransactionContext();
  const { quotes, isLoading, isError } = useUpdateQuote();
  const { params } = useParamContext();
  const { onlyOnramps } = params.buy;
  const { onlyOfframps } = params.sell;
  const [onramps, setOnramps] = useState<Onramp[]>([]);
  const [categorizedOnramps, setCategorizedOnramps] =
    useState<Map<OnrampCategory, Onramp[]>>();

  const filterOnrampsByQueryParams = useCallback(() => {
    const onrampsToFilter =
      transactionType === "buy" ? onlyOnramps : onlyOfframps;
    if (isError) return [];
    if (onrampsToFilter && onrampsToFilter.length !== 0) {
      const onrampNames = onrampsToFilter?.toLowerCase()?.split(",");
      if (onramps?.length === 0) {
        return quotes;
      }
      return quotes?.filter((q) => onrampNames?.includes(q.ramp.toLowerCase()));
    }
    return quotes;
  }, [
    onlyOfframps,
    onlyOnramps,
    onramps?.length,
    quotes,
    transactionType,
    isError,
  ]);

  useEffect(() => {
    if (onramps.length > 0) {
      const withQuotesOnramps: Onramp[] = [];
      const errorOnramps: Onramp[] = [];
      const categories = new Map<OnrampCategory, Onramp[]>();

      onramps.forEach((onramp) => {
        const { errors, recommendations } = onramp;
        if (!errors && recommendations) {
          withQuotesOnramps.push(onramp);
        } else {
          errorOnramps.push(onramp);
        }
      });

      if (withQuotesOnramps.length > 0) {
        categories.set("WithQuotes", withQuotesOnramps);
      }
      if (errorOnramps.length > 0) {
        categories.set("Error", errorOnramps);
      }
      setCategorizedOnramps(categories);
    }
  }, [onramps, setSelectedOnramp]);

  const recommendOnramp = useCallback(() => {
    if (categorizedOnramps) {
      let onrampWithLimitError = null;
      let onrampWithOtherErrors = null;

      const withQuotesOnramps = categorizedOnramps.get("WithQuotes");

      if (withQuotesOnramps && withQuotesOnramps.length > 0) {
        setTransactionError(null);
        setSelectedOnramp(withQuotesOnramps[0]);
      } else if (categorizedOnramps.has("Error")) {
        const errorOnramps = categorizedOnramps.get("Error");

        onrampWithLimitError = errorOnramps?.find((o) =>
          o.errors?.some((e) => e.type === "LimitMismatch")
        );
        if (onrampWithLimitError) {
          setTransactionError("ServerLimitError");
          setSelectedOnramp(onrampWithLimitError);
        } else {
          onrampWithOtherErrors = errorOnramps?.find((o) =>
            o.errors?.some(
              (e) =>
                e.type === "NoSupportedPaymentFound" ||
                e.type === "QuoteParameterMismatch" ||
                e.type === "OnrampQuoteFetchFailed" ||
                e.type === "NoCurrencyLimitsFound"
            )
          );
          onrampWithOtherErrors && setSelectedOnramp(onrampWithOtherErrors);
        }
      }
    }
  }, [categorizedOnramps, setSelectedOnramp, setTransactionError]);

  useEffect(() => {
    const onramps: Onramp[] = [];
    if (isLoading !== true) {
      const filteredOnramps = filterOnrampsByQueryParams() || [];
      filteredOnramps.forEach((quote) => {
        onrampMetadata[quote.ramp]?.isAvailable &&
          onramps.push({
            ...onrampMetadata[quote.ramp],
            rate: quote.rate,
            payout: quote.payout,
            errors: quote.errors,
            quoteId: quote.quoteId,
            recommendations: quote.recommendations,
            availablePaymentMethods: quote.availablePaymentMethods,
          });
      });
      setOnramps(onramps);
    }
  }, [filterOnrampsByQueryParams, isLoading]);

  return { onramps, categorizedOnramps, recommendOnramp };
};
