import React, { FC, useEffect } from 'react';
import { useParamContext, useTransactionContext } from '../../../../providers';
import styles from './RecurringPaymentToggle.module.css';
import { Switch } from '@onramper/oui';
import { useLanguage } from '../../../../hooks/useLanguage';

const RecurringPaymentToggle: FC = () => {
  const { formatMessage } = useLanguage();
  const { params } = useParamContext();
  const {
    transaction: { isRecurringPayment },
    setRecurringPayment,
  } = useTransactionContext();
  const { supportRecurringPayments } = params.buy;

  useEffect(() => {
    if (supportRecurringPayments) {
      setRecurringPayment(false);
    }
  }, [setRecurringPayment, supportRecurringPayments]);

  if (!supportRecurringPayments) {
    return null;
  }

  const handleToggleChange = () => {
    setRecurringPayment(!isRecurringPayment);
  };

  return (
    <div className={styles['recurring-payment-root']}>
      <p>{formatMessage('recurringPayment.recurringPaymentToggle.label')}</p>
      <div className={styles['recurring-payment-tooltip-icon']}>
        {/* <Tooltip
          description='Schedule the payment to repeat automatically for the above amounts.'
          className={styles['recurring-payment-tooltip']}
          position='left'
        >
          <InfoIcon width="15px" height="15x" fill="var(--oui-currency-card-button-selected-color)" />
        </Tooltip> */}
      </div>
      <Switch checked={isRecurringPayment} onChange={() => handleToggleChange()} />
    </div>
  );
};
export default RecurringPaymentToggle;
