import { Button } from "@onramper/oui";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { transactionType } from "viem";
import * as constants from "../../../constants";
import { useCountry } from "../../../hooks/useCountry";
import { useDefaults } from "../../../hooks/useDefaults";
import { useLanguage } from "../../../hooks/useLanguage";
import useLimitError from "../../../hooks/useLimitError";
import { usePaymentMetadata } from "../../../hooks/usePaymentMetadata";
import { useRumEvents } from "../../../hooks/useRumEvents";
import { useSupportedCountries } from "../../../hooks/useSupportedCountries";
import { useUpdateQuote } from "../../../hooks/useUpdateQuote";
import {
  useNotificationContext,
  useParamContext,
  useTransactionContext,
} from "../../../providers";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import { useNavigationContext } from "../../../providers/NavigationContextProvider";
import { Quote } from "../../../types";
import RepeatPaymentInfoView from "../RepeatPaymentInfoView";
import Steps from "../Steps";
import styles from "./CryptoView.module.css";
import CurrencyInfo from "./Currency/CurrencyInfo";
import OnrampInfo from "./Onramp";
import PaymentMethodInfo from "./PaymentMethods";
import RecurringPaymentToggle from "./RecurringPayments/RecurringPaymentToggle";
import { SignIn } from "../SignInView";

const BuyCryptoView: FC = () => {
  const { formatMessage } = useLanguage();
  const { nextScreen } = useNavigationContext();
  const { transaction } = useTransactionContext();
  const { addNotification, removeNotification } = useNotificationContext();
  const {
    params: { country, supportOtcTxn, enableAuth },
  } = useParamContext();
  const {
    quotes,
    isLoading: quotesLoading,
    isError: quotesError,
    refetch: updateQuote,
  } = useUpdateQuote();
  const {
    fiatAmount,
    selectedCrypto,
    selectedFiat,
    selectedOnramp,
    selectedPaymentMethod,
    selectedCountry,
    isRecurringPayment,
    error: transactionError,
    isOtcTxn,
  } = transaction;
  const [quote, setQuote] = useState<Quote | null>(null);
  const debouncedUpdateQuote = useDebouncedCallback(updateQuote, 600);
  const { refetch: fetchPaymentMetadata } = usePaymentMetadata();
  const { refetch: fetchDefaults } = useDefaults(
    country ?? selectedCountry?.countryCode.toLowerCase(),
    "buy"
  );
  const { refetch: fetchSupportedCountries } = useSupportedCountries();
  const limitError = useLimitError();
  const { triggerClickOtcTxnEvent, triggerBuyCryptoEvent } = useRumEvents();
  const countryCode = useCountry();
  const { auth } = useAuthContext();
  const { isAuthenticated } = auth;

  useEffect(() => {
    fetchDefaults();
  }, [fetchDefaults, selectedCountry, transactionType]);

  useEffect(() => {
    fetchPaymentMetadata();
  }, [fetchPaymentMetadata]);

  useEffect(() => {
    fetchSupportedCountries();
  }, [fetchSupportedCountries, selectedCountry]);

  useEffect(() => {
    if (
      selectedCrypto?.id &&
      selectedFiat?.code &&
      selectedPaymentMethod?.name &&
      fiatAmount > 0 &&
      transactionError === null
    ) {
      debouncedUpdateQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedUpdateQuote,
    fiatAmount,
    selectedCrypto?.id,
    selectedFiat?.code,
    selectedPaymentMethod?.name,
    isRecurringPayment,
    transactionError,
  ]);

  useEffect(() => {
    if (!quotesLoading && quotesError && fiatAmount > 0) {
      addNotification({
        type: "error",
        title: formatMessage("buyCryptoView.notification.noQuotes.error.title"),
        message: formatMessage(
          "buyCryptoView.notification.noQuotes.error.message"
        ),
        shouldExpire: false,
        id: "NOBUYQT",
      });
    } else {
      const quote = quotes?.find(
        (q) => q.ramp === selectedOnramp?.id.toLowerCase()
      );
      quote && setQuote(quote);
      removeNotification("NOBUYQT");
    }
  }, [
    quotes,
    quotesError,
    quotesLoading,
    selectedOnramp?.id,
    addNotification,
    removeNotification,
    fiatAmount,
    formatMessage,
  ]);

  const buyButtonOnClickHandler = useCallback(() => {
    if (supportOtcTxn && isOtcTxn) {
      triggerClickOtcTxnEvent();
      window.open(constants.COINIFY_OTC_URL);
    } else {
      triggerBuyCryptoEvent();

      if (!isAuthenticated && enableAuth) {
        nextScreen(<SignIn signInFrom="checkout" />);
      } else if (isRecurringPayment) {
        nextScreen(<RepeatPaymentInfoView key="Steps" />);
      } else {
        nextScreen(<Steps key="Steps" />);
      }
    }
  }, [
    supportOtcTxn,
    isOtcTxn,
    isAuthenticated,
    triggerClickOtcTxnEvent,
    triggerBuyCryptoEvent,
    isRecurringPayment,
    nextScreen,
  ]);

  function getButtonLabel(): string {
    if (countryCode?.toUpperCase() === constants.GB_COUNTRY_CODE)
      return formatMessage("buyCryptoView.button.onramp");

    if (supportOtcTxn && isOtcTxn) {
      return formatMessage("buyCryptoView.tryButton.otc");
    }
    return selectedCrypto?.code
      ? formatMessage("buyCryptoView.buyButton.buy", {
          code: selectedCrypto.code,
        })
      : "Buy";
  }

  return (
    <>
      <CurrencyInfo
        quote={quote ?? undefined}
        error={limitError}
        quotesLoading={quotesLoading}
      />
      <div
        style={{
          display: "flex",
          gap: "16px",
          justifyContent: "space-between",
        }}
      >
        {((!quotesError && transactionError === null && fiatAmount > 0) ||
          (supportOtcTxn && isOtcTxn)) && (
          <OnrampInfo quote={quote ?? null} loading={quotesLoading} />
        )}
      </div>
      {!(supportOtcTxn && isOtcTxn) && <RecurringPaymentToggle />}
      {!(supportOtcTxn && isOtcTxn) && <PaymentMethodInfo />}
      <div className={`${styles["crypto-view-button"]}`}>
        <Button
          label={getButtonLabel()}
          size="large-rectangle"
          expanded
          disabled={
            // eslint-disable-next-line no-nested-ternary
            supportOtcTxn && isOtcTxn
              ? false
              : !selectedCrypto?.code ||
                  quotesLoading ||
                  quotesError ||
                  !quote ||
                  quote?.errors
                ? true
                : false ||
                  transactionError === "ClientLimitError" ||
                  transactionError === "ServerLimitError"
          }
          onClick={() => buyButtonOnClickHandler()}
        />
      </div>
    </>
  );
};

export default BuyCryptoView;
