import { Icon, ListItem } from '@onramper/oui';
import Fuse from 'fuse.js';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FiatCurrency } from '../../../../../types';
import BottomOverlay from '../../../BottomOverlay/BottomOverlay';
import SelectionView from '../../../SelectionView';
import { FiatPickerProps } from './FiatPickerProps';

const FiatCurrencyPicker: FC<FiatPickerProps> = (props: FiatPickerProps) => {
  const { title, currencies, onSelectCurrency } = props;
  const [searchedItems, setSearchedItems] = useState<FiatCurrency[] | undefined>();

  const fuse = useMemo(
    () =>
      new Fuse(currencies ?? [], {
        keys: [
          {
            name: 'code',
            weight: 1,
          },
          {
            name: 'name',
            weight: 2,
          },
        ],
      }),
    [currencies],
  );

  const currencySearchHandler = useCallback(
    (searchKey: string) => {
      if (searchKey && searchKey.trim().length > 0) {
        const result = fuse.search(searchKey).map((value) => value.item);
        setSearchedItems(result);
      } else {
        setSearchedItems(undefined);
      }
    },
    [fuse],
  );

  return (
    <BottomOverlay title={title}>
      <SelectionView<FiatCurrency>
        keyExtractor={({ id }) => id}
        items={searchedItems ?? currencies}
        searchable
        onSelectItem={onSelectCurrency}
        onSearch={currencySearchHandler}
        renderItem={({ id, code, name }) => (
          <ListItem
            primaryText={code}
            secondaryText={name}
            primaryIcon={
              <Icon iconSrc={`https://cdn.onramper.com/icons/fiats/${id}.svg`} size="medium" avatarName={name} />
            }
          />
        )}
      />
    </BottomOverlay>
  );
};
export default FiatCurrencyPicker;
